import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CartProgressIndicatorState = {
  // is null at the beginning to support dynamic import and animation when first rendered
  isOpen: boolean | null;
  lastTriggerTimestamp: number | null;
  freeDeliveryMessageVisible: boolean;
  visibleRewardDescription: 'gift' | 'delivery' | null;
  isConfettiAnimationVisible: boolean;
};

const initialState: CartProgressIndicatorState = {
  isOpen: null,
  lastTriggerTimestamp: null,
  freeDeliveryMessageVisible: false,
  visibleRewardDescription: null,
  isConfettiAnimationVisible: false,
};

export const cartProgressIndicatorSlice = createSlice({
  name: 'cartProgressIndicator',
  initialState,
  reducers: {
    showCartProgressIndicator: (state) => {
      state.isOpen = true;
      state.lastTriggerTimestamp = Date.now();
    },
    showReachFreeDeliveryMessage: (state, action: PayloadAction<boolean>) => {
      state.freeDeliveryMessageVisible = action.payload;
    },
    hideCartProgressIndicator: (state) => {
      state.isOpen = false;
    },
    showConfettiAnimation: (state, action: PayloadAction<boolean>) => {
      state.isConfettiAnimationVisible = action.payload;
    },
    showRewardsDescription: (
      state,
      action: PayloadAction<
        CartProgressIndicatorState['visibleRewardDescription']
      >
    ) => {
      state.visibleRewardDescription = action.payload;
    },
  },
});

export const {
  showCartProgressIndicator,
  hideCartProgressIndicator,
  showReachFreeDeliveryMessage,
  showRewardsDescription,
  showConfettiAnimation,
} = cartProgressIndicatorSlice.actions;

export default cartProgressIndicatorSlice.reducer;
